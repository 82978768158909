<template>
  <div class="widget-page-114" :style="componentStyle">
    <template v-for="(widget,index) in widgetList()">
      <component  :is="widget.widget" :dashboardData="widget" :index="index" :key="index" />
    </template>
  </div>
</template>
<script>

import GeneralService from "@/services/GeneralService.js";
import Moment from "moment-timezone";

// The REAL widgets are getting created here --
import News from "@/widgets/News.vue";
import Clock from "@/widgets/Clock";
import Header from "@/widgets/Header";

export default {
  props: {
    cStyle          : { type: String, default:"" },
    dashboardData   : { type: String, default: "" },
    dashboardWidgets: { type: Array, default: () => [] }
  },
  components: {
    News,
    Clock,
    Header
  },
  data() {
    return {
      pageInterval  : null,
      updateSeconds : 30,     // how often we update/eval this component
      secondsCounter: 0,
      refreshCounter: 0,
      lastEvent     : null,
      componentStyle: null,
      data          : {},
      widgets       : []
    }
  },
  computed: {
  },
  beforeDestory() {
    this._clearAllIntervals();
  },
  created() {
    console.log( "Widgets component created");
  },
  async mounted() {
    // setup data
    try {
      if( this.dashboardData )     { 
        this.data = JSON.parse( this.dashboardData );
      }
    } catch( error ) {
      console.log( error.message );
    }

    this.updateSeconds  = this.getSetting( "displaySeconds" , 10 );  // this.dashboardData.displaySeconds || 10;
    this.componentStyle = this.getSetting( "componentStyle" , "" );
    this.widgets        = this.getSetting( "Widgets" , []);

    // setup timers
    this._clearAllIntervals();
    this.pageInterval = setInterval( this.pageTimer,1000);
  },
  methods: {
    isTrue( o , defaultValue) {
      if( typeof o === 'undefined' ) {
        return defaultValue;
      }
      return o;
    },
    widgetList() {
      console.log( this.widgets );
      let wl = this.widgets.filter((w) => { return this.isTrue(w.active,true) });
      return wl;
    },
    getSetting( setting , defaultData ) {
      if( setting in this.data ) {
        return this.data[setting];
      }
      return defaultData;
    },
     _clearAllIntervals() {
      clearInterval( this.pageTimer );
    },
    _updateCounters() {
      let t=this;
      t.secondsCounter++;
      t.refreshCounter++;
    },
    pageStyle() {
      if( this.componentStyle ) {
        if( this.componentStyle.page ) {
          return this.componentStyle.page;
        }
      }
      return "";
    },
    async pageTimer() {
      let t = this;

      let rightNow       = new Date();
      let currentSeconds = rightNow.getSeconds();

      t.lastEvent = rightNow;
      t._updateCounters();

      if( t.secondsCounter >= t.updateSeconds ) {   // Check for update from server.
        // do something here...
        t.secondsCounter = 0;
      }

      if( t.refreshCounter > 600 ) {    // not sure why this is 600
        t.refreshCounter = 0;
        // go get some config or something...
      }

    }
  }

}
</script>
<style >

  .widget-page-114 {
   
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 2px 2px;
    grid-template-areas:
      'leftside main main main'
      'leftside main main main'
      'leftside main main main'
      'bottom1 bottom2 bottom3 bottom4';
  }


  .widget-main { grid-area: main; }

  .widget-bottom1 { grid-area: bottom1; }

  .widget-bottom2 { grid-area: bottom2; }

  .widget-bottom3 { grid-area: bottom3; }

  .widget-bottom4 { grid-area: bottom4; }

  .widget-left { grid-area: leftside; }

  .basic-widget {
    overflow:hidden;
  }


</style>
