<template>
  <div :style="cstyle">
    <h2 class="title" >News 2</h2>
    <p>This is some text to be put here  -- can you see this anywhere??</p>
  </div>
</template>
<script>
export default {
  props: {
    cstyle: {type:String, default:""}
  },
  created() {
    console.log( "News2 component created");
  }
  
}
</script>
<style scoped>

  .title {
    color: black;
  }

  h2 {
    font-size: 72pt;
    text-align:center;
    background:gray;
  }

  p {
    font-size:24pt;
    line-height:22pt;
  }

</style>