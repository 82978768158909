<template>
  <div class="basic-widget"  :class="data.section"    :style="pageStyle()">
    <div v-if="!isHideTime"  :class="data.timeClass"  :style="timeStyle" >{{currentTime}}</div>
    <div v-if="!isHideDate"  :class="data.dateClass"  :style="dateStyle" >{{currentDate}}</div>
    <div v-if="!isHideTitle" :class="data.titleClass" :style="titleStyle">{{title}}</div>
  </div>
</template>
<script>

import Moment from "moment-timezone";

export default {
  props: {
    index        : { type: Number, default: 0 },
    dashboardData: { type: Object, default: () => {} }
  },
  data() {
    return {
      timeOffset          : 0,
      pageInterval        : null,                                                                   // standard component stuff.
      updateSeconds       : 30,
      secondsCounter      : 0,
      refreshCounter      : 0,
      lastEvent           : null,
      componentStyle      : {"page":"margin-top:10px;height:160px;overflow:hidden;"},
      titleStyle          : "margin:-.5em;padding:0;color:white;font-size:36px;font-weight:100;",
      timeStyle           : "margin:-.5em;color:white;font-size:96px;",
      dateStyle           : "margin:0;color:white;font-size:48px;",
      data                : {},
      isOdd               : true,
      isHideDay           : false,
      isHideDate          : false,
      isHideTime          : false,
      isHideTitle         : false,
      isToggleTitle       : true,
      title               : "",
      timezone            : "America/Chicago",                                                      // specific to THIS widget...
      currentDayFormat    : "dddd, MMM Do",
      currentDay          : "Sunday",
      currentTime         : "",
      currentDate         : "",
      currentTimeFormatOn : "h:mm a",
      currentTimeFormatOff: "h mm a",
      currentDateFormat   : "dddd, MMM Do",
    }
  },
  beforeDestory() {
    this._clearAllIntervals();
  },
  created() {
    console.log( "WidgetClock component created");
  },
  async mounted() {
    // setup data
    try {
      if( this.dashboardData )     {
        this.data                 = this.dashboardData;                                                   // JSON.parse( this.dashboardData );
        this.title                = this.getSetting( "title" , "" );
        this.timezone             = this.getSetting( "timezone" , this.$root.TZ );
        this.timeOffset           = this.getSetting( "timeOffset" , 0 );
        this.isHideDay            = this.getSetting( 'hideDay',false);
        this.isHideTime           = this.getSetting( 'hideTime',false);
        this.isHideDate           = this.getSetting( 'hideDate',true);
        this.isHideTitle          = this.getSetting( 'hideTitle',false);
        this.isToggleTitle        = this.getSetting( 'toggleTitle',true);
        this.componentStyle       = this.getSetting( "componentStyle" , this.componentStyle );
        this.titleStyle           = this.getSetting( "titleStyle" , this.titleStyle );
        this.timeStyle            = this.getSetting( "timeStyle" , this.timeStyle );
        this.dateStyle            = this.getSetting( "dateStyle" , this.dateStyle );
        this.currentTimeFormatOn  = this.getSetting( "currentTimeFormatOn", this.currentTimeFormatOn);
        this.currentTimeFormatOff = this.getSetting( "currentTimeFormatOff", this.currentTimeFormatOff);
        this.currentDateFormat    = this.getSetting( "currentDateFormat", this.currentDateFormat );
        this.currentDayFormat     = this.getSetting( "currentDayFormat", this.currentDayFormat );
      }
    } catch( error ) {
      console.log( error.message );
    }
    this.updateSeconds = this.dashboardData.displaySeconds || 10;

    this._clearAllIntervals();
    this.pageInterval = setInterval( this.pageTimer,1000);
  },
  methods: {
    getSetting( setting , defaultData ) {
      if( setting in this.data ) {
        return this.data[setting];
      }
      return defaultData;
    },
    pageStyle() {
      if( this.componentStyle ) {
        if( this.componentStyle.page ) {
          return this.componentStyle.page;
        }
      }
      return "";
    },
    _clearAllIntervals() {
      clearInterval( this.pageTimer );
    },
    async pageTimer() {
      let t = this;
      this.secondsCounter++;

      if( this.secondsCounter % 2 ===0 ) {
        this.currentTime = Moment.tz(this.timezone).add(this.timeOffset,'hour').format( this.currentTimeFormatOn );
      } else {
        this.currentTime = Moment.tz(this.timezone).add(this.timeOffset,'hour').format( this.currentTimeFormatOff );
      }

      if((this.secondsCounter % 5) === 0) {

        this.currentDate = Moment.tz(this.timezone).add(this.timeOffset,'hour').format( this.currentDateFormat );

        if( this.isOdd ) {
          this.isOdd = false;
          if( this.isHideDay === false) {
            this.title = Moment.tz(this.timezone).add(this.timeOffset,'hour').format(this.currentDayFormat);
          } else {
            this.title = this.getSetting( "title" , this.title );            
          }
        } else {
          this.isOdd = true;
          if( this.isHideDay === false) {
            if( this.isToggleTitle === true ) {
               this.title = this.getSetting( "title" , this.title );
            } else {
              this.title = Moment.tz(this.timezone).add(this.timeOffset,'hour').format(this.currentDayFormat);
            }
          } else {
            this.title = this.getSetting( "title" , this.title );
          }
        }
      }

    },

  }
}
</script>
<style scoped>

  

</style>