<template>
  <div class="calendar-page" :style="cstyle">
    <div class="row">
      <div class="col-12">
        <h1 class="calendar-page-title">{{menuBoard.title}}</h1>
        <p v-if="menuBoard.description" style="margin-top:0;margin-bottom:0; font-size:3vh;">{{menuBoard.description}}</p>
      </div>
    </div>
    <div class="row calendar-row">
      <div class="col-4 calendar-column" v-for="(board, bindex) in menuBoard.column" :key="bindex">
        <h2 class="calendar-column-header">{{board.title || "Special" }}</h2>
        <div class="calendar-items" v-if="board.choices" >
          <!-- <div v-if="board.description">
            <p class="calendar-column-description">{{board.description}}</p>
            <hr>
          </div> -->
          <div v-for="(item,itemIndex) in board.choices" :key="itemIndex">
            <div v-if="board.showPrice">
              <h3 class="calendar-item-title" v-if="item.title">{{item.title}}<span>{{ (item.price) ? parseFloat(item.price).toFixed(2) : parseFloat(item.price[0]).toFixed(2) }}</span></h3>
              <p class="calendar-item-description" v-if="item.description">{{item.description}}</p>
            </div>
            <div v-else>
              <h3 class="calendar-item-title-no-price" v-if="item.title">{{item.title}}</h3>
              <p class="calendar-item-description-no-price" v-if="item.description">{{item.description}}</p>
            </div>
          </div>
        </div>
        <div v-if="board.description" class="calendar-column-footer">{{board.description}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import GeneralService from "@/services/GeneralService.js";

  export default {
    name: 'dynamic-menu-calendar',
    props: {
      cstyle       : {type:String, default:""},
      dashboardData: {type: String, default:""},
      config       : { type: Object, default: () => { return { id:1, displaySeconds:10}}}
  },
   data() {
    return {
      calendarId      : null,
      pageInterval    : null,
      updateSeconds   : 30,     // how often we update/eval this component
      secondsCounter  : 0,
      refreshCounter  : 0,
      menuBoard: {},
      // "menuBoard": {
      //   "tags": "cafe",
      //   "column": [
      //     { "title": "Always Available", 
      //       "fixed": true, 
      //       "showPrice":true,
      //       "choices": [
      //         { "title": "Hamburger" , "description": ""," price": 3.50},
      //         { "title": "Sandwich Meal Combo" , "description": "","price": 5.00},
      //         { "title": "Grilled Cheese Sandwich" , "description": "","price": 4.00},
      //         { "title": "Grilled Chicken Sandwich" , "description": "","price": 3.75},
      //         { "title": "Fish Sandwich" , "description": "","price": 3.50},
      //         { "title": "Speciality Sandwich" , "description": "","price": 4.00},
      //         { "title": "Turkey & Cheese Wrap" , "description": "","price": 4.00},
      //         { "title": "Ham & Cheese Wrap" , "description": "","price": 4.00},
      //         { "title": "Flat-Breads" , "description": "","price": 4.00},
      //         { "title": "Fish & Chips" , "description": "","price": 3.50},
      //         { "title": "Chicken Tenders" , "description": "","price": 3.75},
      //         { "title": "Soup De Jour" , "description": "","price": 1.00},
      //       ]
      //     },
      //      { "title": "Drinks", 
      //      "fixed": true, 
      //      "showPrice":true,
      //      "choices": [
      //         { "title": "Can Soda" , "description": "","price": 1.00},
      //         { "title": "Bottled Water" , "description": "","price": 1.00}
      //       ]
      //     }
      //   ]
      // },
      lastEvent       : null,
      calendarItems   : null
    }
  },
   beforeDestory() {
    this._clearAllIntervals();
  },
  created() {
    console.log( "Calendar component created");

  // console.log( "menuBoard = " , this.menuBoard );

  },
async mounted() {
    // setup data
    // this.newsId        = this.config.id || -1;
    this.updateSeconds = this.config.displaySeconds || 10;
    await this.getCalendarItems();
    // await this.getNews();

    // setup timers
    this._clearAllIntervals();
    this.pageInterval = setInterval( this.pageTimer,1000);
  },
  methods: {
     _clearAllIntervals() {
      clearInterval( this.pageTimer );
    },
    _updateCounters() {
      let t=this;
      t.secondsCounter++;
      t.refreshCounter++;
    },
    async pageTimer() {
      let t = this;

      let rightNow       = new Date();
      let currentSeconds = rightNow.getSeconds();

      t.lastEvent = rightNow;
      t._updateCounters();

      if( t.secondsCounter >= t.updateSeconds ) {   // Check for update from server.
        //t.rotateNews();
        t.secondsCounter = 0;
      }

      if( t.refreshCounter > 600 ) {
        t.refreshCounter = 0;
        await t.getCalendarItems();
      }

    },
   
    async getCalendarItems() {

      // console.log( "MenuCalendar().getCaledarItems()");

      try {

        this.menuBoard = JSON.parse( this.dashboardData );

        let response = await GeneralService.getDailyMenu( this.menuBoard.tags )
        this.calendarItems = response.data;

        this.menuBoard.column.push( response.data[0].menu[0] );

        // console.log( "Calendar items = ", this.calendarItems );

      } catch( error ) {
        console.log( error.message );
      }
    
    }
  }


  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .calendar-page-title {
    font-size:8vw;
    color:white;
    font-family: "Trebuchet MS", Helvetica, sans-serif;
    text-shadow: 2px 2px 0 #bcbcbc, 4px 4px 0 #9c9c9c, 14px 11px 9px rgba(30,13,8,0.56);
  }
  .calendar-row {
    margin-left:1em;
    margin-right:1em;
  }

  .calendar-column {
    margin-top:2.5vw;
  }

  .calendar-column-header {
    font-size:2.5vw;
    color:white;
    margin-bottom:.5em;
    font-family: "Arial", Helvetica, sans-serif;
    text-shadow: 9px 9px 14px rgba(0,0,0,0.34);
  }

  .calendar-column-footer {
    position:relative;
    background:white;
    -webkit-border-radius: 1.25em 1.25em 1.25em 1.25em;
    -moz-border-radius: 1.25em 1.25em 1.25em 1.25em;
    border-radius: 1.25em 1.25em 1.25em 1.25em;
    top: 2vw;
    font-family: "Arial";
    font-weight:100;
    white-space:pre-line;
    line-height:97%;
    margin-left:5%;
    margin-right:5%;
    padding:2%;

    -webkit-box-shadow: 10px 12px 29px 5px rgba(0,0,0,0.67);
    box-shadow: 10px 12px 29px 5px rgba(0,0,0,0.67);
    //-webkit-box-shadow: 0px 0px 42px -2px rgba(255,67,28,0.49), 9px 10px 42px -2px rgba(255,191,118,0.49);
    //box-shadow: 0px 0px 42px -2px rgba(255,67,28,0.79), 9px 10px 42px -2px rgba(255,80,118,0.49);
  }

  .calendar-column-description {
    white-space:pre-line;
    font-size:1.1vw;
    font-family: "Arial", Helvetica, sans-serif;
    line-height:95%;
    text-align:left;
    padding-left:2.5vw;
    padding-right:2.5vw;
  }

  .calendar-items {
    padding-top:1em;
    background:white;
    width:90%;
    height:90%;
    margin-left:auto;
    margin-right:auto;
    border-radius:1em;
    overflow-x:hidden;
    -webkit-box-shadow: 10px 12px 29px 5px rgba(0,0,0,0.67); 
    box-shadow: 10px 12px 29px 5px rgba(0,0,0,0.67);
  }

  .calendar-item-title {

    font-size:1.4vw;
    text-align:right;
    margin-bottom:0;
    span {
      display:inline-block;
      float:right;
      font-size:1.25vw;
      min-width:5.75em;
      padding-right:1.5em;
      text-align:right;
      font-weight:900;
    }
  }


  .calendar-item-title-no-price {
    font-size:1.4vw;
    text-align:center;
    margin-bottom:5px;
    span {
      display:inline-block;
      float:right;
      font-size:1.25vw;
      min-width:5.75em;
      padding-right:1.5em;
      text-align:right;
    }

  }

  .calendar-item-description {
    white-space:pre-line;
    font-size:1vw;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    line-height:94%;
    // margin-top:-1%;
    text-align:center;
    padding-right:8.5vw;
  }

 .calendar-item-description-no-price {
    white-space:pre-line;
    margin-top:-4px;
    margin-bottom:8px;
    font-size:1vw;
    // font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-family: Arial, Helvetica, sans-serif;
    line-height:94%;
    // margin-top:-1%;
    text-align:center;
    // padding-right:8.5vw;

  }


</style>
