<template>
  <div class="dynamic">
    <h1>Hello from dynamic-menuItems</h1>


  </div>
</template>

<script>
export default {
  name: 'dynamic-menuitems',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .dynamic {
    background:red;
  }


</style>
