<template>
  <div class="basic-widget" :class="data.section" :style="componentStyle">
    <div :class="data.titleClass" :style="titleStyle">{{title}}</div>
  </div>
</template>
<script>

import Moment from "moment-timezone";

export default {
  props: {
    index        : { type: Number, default: 0 },
    dashboardData: { type: Object, default: () => {} }
  },
  data() {
    return {
      lastEvent     : null,
      componentStyle: "min-height:164px;",
      titleStyle    : "color:white;font-size:64px;",
      data          : {},
      title         : ""                              // specific to THIS widget...
    }
  },
  beforeDestory() {
    // this._clearAllIntervals();
  },
  created() {
    console.log( "WidgetClock component created");
  },
  async mounted() {
    // setup data
    try {
      if( this.dashboardData )     {
        this.data           = this.dashboardData;                                         // JSON.parse( this.dashboardData );
        this.title          = this.getSetting( "title" , "" );
        this.componentStyle = this.getSetting( "componentStyle" , this.componentStyle );
        this.titleStyle     = this.getSetting( "titleStyle", this.titleStyle);
      }
    } catch( error ) {
      console.log( error.message );
    }
  },
  methods: {
    getSetting( setting , defaultData ) {
      if( setting in this.data ) {
        return this.data[setting];
      }
      return defaultData;
    }

  }
}
</script>
<style scoped>


</style>