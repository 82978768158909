<template>
  <div style="width:100%;background:purple;">

    <div class="kiosk-page" :style="pageStyle">

      <div v-if="!currentFile && !dashboardId" style="min-width:100%;text-align:center;padding-top:10%">
        <template v-if="this.$root.siteInfo" >
          <h1 style="font-size:72px;">{{this.$root.siteInfo.title}}</h1>
          <h2 style="font-size:128px;">{{this.$root.siteInfo.options.defaultPrompt}}</h2>
        </template>
        <div style="font-align:center;font-size:100px;font-style:bold;color:white;">{{currentDay}}</div>
        <div class="offline-time" style="width:100%;padding: 0 .25em 0 .25em;margin:0;font-align:center;font-size:200px;line-height:168px;font-family:Arial,sans-serif;font-style:bold;color:white;">{{currentTime}}</div>
      </div>


      <!-- <component cstyle="position:absolute;top:100px;left:100px;width:400px;" :is="pageComponent">
      </component> -->

      <div v-if="currentFile>'' && currentFile.indexOf('.mp4')>=0" >

        <!-- <video class="html5-video" style="width:100%;" id="guess-video" loop="" muted="" autoplay="" preload="auto" playsinline="" @onended="videoComplete()"> -->
        <video class="html5-video" style="width:100%;z-index:0;" id="guess-video"  loop="" muted="" autoplay="" preload="auto" playsinline="" @onended="videoComplete" @seeked="videoSeeked" @progress="videoProgress" @play="videoPlay">
				  <source :src="currentFile" :data-src="currentFile" type="video/mp4">
        </video>
          <!-- <source data-src="assets/guess-home.webm" src="assets/guess-home.webm" type="video/webm">
					<source data-src="assets/guess-home.ogv" src="assets/guess-home.ogv" type="video/ogv"> -->

        <!-- <video ref="videocontrol" :key="currentFile" type="video" aspect="16by9" :loop=true allow="autoplay" :autoStart=true :autoPlay=true  allowfullscreen >
          <source :src="currentFile" type="video/mp4"  />  
        </video> -->
      </div>

      <div v-if="currentFile && currentFile.indexOf('.png')>=0">
        <img  v-if="currentFile" class="image" :src="currentFile">
      </div>

      <div v-if="currentFile && currentFile.indexOf('.jpg')>=0">
        <img  v-if="currentFile" class="image" :src="currentFile">
      </div>

      <div v-if="currentFile && currentFile.indexOf('.jpeg')>=0">
        <img  v-if="currentFile" class="image" :src="currentFile">
      </div>

      <div v-if="currentFile && currentFile.indexOf('.svg')>=0">
        <img  v-if="currentFile" class="image" :src="currentFile">
      </div>

      <div v-if="dashboardId>''">
        <div style="position:absolute;top:0;left:0;width:100%;height:100%;overflow:hidden;">
          <component :is="dashboardId" :dashboardData="dashboardData" :dashboardWidgets="widgets"/>
        </div>
      </div>

      <div v-if="showClock" class="clock-display">
        <div>{{currentDay}}</div>
        <div>{{currentTime}}</div>
      </div>

      <div  style="position:absolute;bottom:5px;left:25px;cursor:pointer;font-size:.5em;">{{currentScheduleIndex}}:{{currentImageIndex}}:{{$root.device.title}}</div>
      <!-- <div  style="position:absolute;bottom:5px;left:25px;cursor:pointer;" @click="$root.deactivate()">{{$root.device.title}}</div> -->
    </div>

  </div>
</template>

<script>

// Video "event" documentation link: https://www.w3schools.com/tags/ref_av_dom.asp

// common display resolutions:
// 1366x768
// 1920x1080
// 2560x1600

/* -----------------------------------------------------------------------[ How this page works (writing this for you, Wolfie)]


  On Mount

    - get the schedule
    - Get the "update frequency" - this is set at the device configuration level

  on Timer

    - ask of any changes


*/

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import News from "@/components/widgets/News.vue";
import News2 from "@/components/widgets/News2.vue";
import YouTube from "@/components/widgets/YouTube.vue";
import MenuItems from "@/components/widgets/MenuItems";
import MenuCalendar from "@/components/widgets/MenuCalendar";
import Widget114 from "@/components/widgets/Widget114";
import Widget411 from "@/components/widgets/Widget411";
import GeneralService from "@/services/GeneralService.js";
import Moment from "moment-timezone";

export default {
  name: 'page',
  components: {
    News,
    News2,
    MenuItems,
    MenuCalendar,
    YouTube,
    Widget114,
    Widget411
  },
  data() {
    return {
      baseUrl              : null,
      checkForUpdate       : 600,
      componentValue       : true,
      currentDay           : "Welcome",
      currentDisplaySeconds: null,
      currentFile          : "",                        // https://newcareoperations0000.blob.core.windows.net/tenant-5be31868ce1e153f60e8b919/kiosk/litebites-menuboard-3.png?se=2019-11-30T10%3A58%3A00Z&sp=rl&sv=2018-03-28&sr=c&sig=LF5dDo17f1pjQynNHiBvsbLeTJvGK2TiQdanqaId7sY%3D",
      currentImageIndex    : 999999,                    // force restart on first image.
      currentScheduleIndex : 999999,
      currentTime          : "",                        // this.updateTime(),
      dashboardData        : {},
      dashboardId          : "",
      displaySeconds       : null,
      dynamicView          : "",
      height               : "1080px",
      imageTimer           : 0,
      isDefault            : true,                      // if we don't have a page yet...
      isPlaceHolder        : false,
      lastEvent            : null,
      lastUpdate           : "never",
      pageInterval         : null,
      pageStyle            : "",
      pageToken            : null,
      schedules            : null,
      secondsCounter       : new Date().getSeconds(),
      showClock            : true,
      siteInfo             : null,
      updateMinutes        : 0,
      widgets              : [],
      width                : "1920px"
    }
  },
  computed: {
    
  },
  beforeDestory() {
    this._clearAllIntervals();
  },
  async created() {

    this.isDefault = !this.$root.isLoggedIn();
    this.$root.schedule = JSON.parse(localStorage.getItem( 'schedule' ));

    if( !this.isDefault ) {
      this.isPlaceHolder = true;
    }

//    await this.getPageToken();
  },
  async mounted() {

    await this.getDeviceSchedule();

    let checkForUpdate= (Math.max(this.$root.siteInfo.updateMinutes || 5,1)*60);
    if( checkForUpdate < 30 ) {
      checkForUpdate = 30;
    }

    this.checkForUpdate = checkForUpdate;
    this._clearAllIntervals();
    this.pageInterval = setInterval( this.pageTimer,1000);
  },
  // _ prefixes mean "internal/event" command
  methods: {
    _clearAllIntervals() {
      clearInterval( this.pageTimer );
    },
    _updateCounters() {
      let t=this;
      if( t.imageTimer > 0 ) {
        t.imageTimer--;
      }
      t.secondsCounter++;
      t.currentDisplaySeconds++;
    },
    _updateTime() {
      this.currentDay = Moment().format("dddd");
      if( this.secondsCounter % 2 ===0 ) {
        this.currentTime = Moment().format( "h:mm a");
      } else {
        this.currentTime = Moment().format( "h mm a");
      }
    },
    videoComplete( e ) {
      // console.log( "Video ended " , e );
    },
    videoAbort( e ) {
      // console.log( "Video abort" , e );
    }, 
    videoPlay( e ) {
      this.imageTimer = Math.floor(parseFloat( e.srcElement.duration) +.8 )
    },
    videoSeeked( e ) {
    },
    videoProgress( e ) {
    },    
    pageTimer() {
      let t = this;

      let rightNow = new Date();
      let currentSeconds = rightNow.getSeconds();

      if( currentSeconds <= 1 ) {     // give ourselves 2 actual seconds to test for a reload request.
        if( this.$root.siteInfo.restartTime === Moment().format( "HH:mm" )) {
          t.inTimer = false;        // jic
          location.reload(true);    // force reload from the server
        }
      }

      if( t.lastEvent === rightNow ) {
        location.reload(true);    // if this happens, there are 2 of these timers running somehow..
      }

      t.lastEvent = rightNow;
      t._updateCounters();
      t._updateTime();

      if( t.secondsCounter >= t.checkForUpdate ) {   // Check for update from server.
        t.getDeviceSchedule();
        t.secondsCounter = 0;
      } else {
        if( t.secondsCounter % 30 === 0  ) {        // every 30 seconds check a change
          t.processSchedule();
          // if( this.pageComponent === "News") {
          //   this.pageComponent = "News2"      // we can dynamically change out this component real-time
          // } else {
          //   this.pageComponent = "News"
          // }
        } 
      }

      if( t.schedules && t.currentScheduleIndex >= 0 ) {           // while we have an active schedule index.
        t.selectCurrentFile();
      }

    },
    getWindowWidth() {
      return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    },
    async getPageToken() {
      try {
        let t=this;
        let response = await GeneralService.getPageToken( "" );

        t.baseUrl   = response.data.base;
        t.pageToken = response.data.token;
      } catch( error ) {
        console.log( error );
      }
    },
    makePageStyle() {
      let t=this;
      this.pageStyle = this.$root.device.pageStyle;

      if( t.currentScheduleIndex >=0 && t.currentScheduleIndex <= t.schedules.length ) {
        let s = t.schedules[ t.currentScheduleIndex ];
        if( s.options.hideCursor ) {
          this.pageStyle += "pointer:none;"
        } else {
          this.pageStyle += "pointer:default;"
        }
        //this.pageStyle +=
      }

    },
    makeFullUrl( filename ) {
      let queryOptions;
      if( !filename ) {
        return "";
      }
      if( filename.indexOf('.mp4') >= 0 )  { 
        queryOptions ="?autoplay=1&";
      } else {
        queryOptions = "?";
      }
      let url = this.baseUrl + "kiosk/" + filename + queryOptions + this.pageToken;
      return url; 
    },
    findActiveScheduleIndex() {
      let t           = this;
      let today       = new Date();
      let weekday     = today.getDay();
      let currentTime = Moment().format( "HH:mm" ).toString();

      if( t.schedules ) {
        for( var index=0; index< t.schedules.length; index++) {
          let s = t.schedules[index];
          if( s.weekdays[ weekday ] === true ) {      // we match for the day of the week.
            if( currentTime >= s.beginTime ) {
              if( currentTime < s.endTime ) {
                return index;
              }
            }
          }
        }
      }
      return -1
    },
    selectCurrentFile() {
      let t = this;

      // console.log("selectCurrentFile()");

      if (t.imageTimer <= 0) {
        t.imageTimer = t.displaySeconds;
        t.currentImageIndex++;
        if (t.currentImageIndex >= t.schedules[t.currentScheduleIndex].files.length) {
          t.currentImageIndex = 0;
        }
        t.currentFile = t.makeFullUrl(t.schedules[t.currentScheduleIndex].files[t.currentImageIndex]);
      }
    },
    processSchedule() {
      let t       = this;
// console.log( "processSchedule()");
      let currentScheduleIndex = t.findActiveScheduleIndex();

      if( currentScheduleIndex < 0 ) {                  // nothing to display 
      console.log( "**************** Nothing to display");
        t.currentScheduleIndex = currentScheduleIndex;  // no schedule, so update data for the rest of the page
        t.currentFile = null;
        return;
      }

      if( currentScheduleIndex != t.currentScheduleIndex ) {   // schedule index changed - 
      // console.log( "setting schedule" , t.schedules[ currentScheduleIndex ])
        t.currentScheduleIndex = currentScheduleIndex;
        t.displaySeconds       = t.schedules[ currentScheduleIndex ].displaySeconds;
        t.dashboardId          = t.schedules[ currentScheduleIndex ].dashboardId;
        t.dashboardData        = t.schedules[ currentScheduleIndex ].dashboardData;
        t.widgets              = t.schedules[ currentScheduleIndex ].widgets;
        t.showClock            = t.schedules[ currentScheduleIndex ].options.showClock ;  // || t.$root.siteInfo.options.showClock;
        t.makePageStyle();
        t.currentDisplaySeconds = 9999999;
        t.imageTimer            = -1; // t.displaySeconds;
        t.currentImageIndex     = -1;
      }

    },
    async getDeviceSchedule() {
      let t = this;
      // console.log( "getDeviceSchedule()" );
      try {

        if( t.baseUrl === null ) {
          await t.getPageToken();
        }

        let response = await GeneralService.hasConfigChanged( t.lastUpdate );

        if( response.data.changed === false )  {
          return;
        }

        response = await GeneralService.getDeviceSchedule( t.$root.device.deviceId);

        t.isDefault     = false;
        t.isPlaceHolder = true;

        if( response.data ) {

          if( response.data.deactivate && response.data.deactivate === true ) {   // time to reactivate
            t.logout();
            return;
          }

          t.lastUpdate = response.data.lastUpdate || "Not Found";
          t.schedules  = response.data.schedules;

          t.currentScheduleIndex = -1;      // 26-Mar-20 wjs -- force this to re-draw when we get a changed config.... (or what I'm hoping)

// console.log( "getDeviceSchedule() return data" , response.data );

          t.$root.setSchedules( response.data.schedules );
          t.$root.setDeviceConfig( response.data.device[0] );

         // this.pageStyle = this.$root.device.pageStyle;

          delete response.data.schedules;
          delete response.data.device;

          t.$root.setSiteInfo( response.data );

          t.processSchedule();       // process the schedule after we get a new one.

        }

      } catch( error ) {
        // todo: hydrate the stuff from webstorage if we get an error...
        console.log( error.message );
      }

    },
    async getFile( filename ) {
      let file =  await GeneralService.getFile( filename );
      return file.data;
    },
    async getImage( imageName ) {
      let image = await GeneralService.getImage( imageName );
      return await image;
    },
    async isFileExist( filename ) {
      let file =  await GeneralService.isFileExist( filename );
      return file.data;
    },
    logout() {
      this.$root.deactivate();
    }
  }
}
</script>
<style lang="scss" >

  // Breakpoints
  $bp-small: 48em; // 768px
  $bp-medium: 64em; // 1024px
  $bp-large: 85.375em; // 1366px
  $bp-xlarge: 120em; // 1920px
  $bp-xxlarge: 160em; // 2560px

  // Media Queries
  $mq-small: "(min-width: #{$bp-small})";
  $mq-medium: "(min-width: #{$bp-medium})";
  $mq-large: "(min-width: #{$bp-large})";
  $mq-xlarge: "(min-width: #{$bp-xlarge})";
  $mq-xxlarge: "(min-width: #{$bp-xxlarge})";
  $mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)"; 

  // Font-Size
  $base-font-size: 1em;

  // Line-Height
  $base-line-height: 1.5;
  $header-line-height: 1.25;

  html,body {
    background:black;
    // min-width:100%;
    // min-height:100%;
  }

  body {
    font-size: $base-font-size;
    line-height: $base-line-height;

    @media #{$mq-medium} {
      font-size: $base-font-size*1.2;
      line-height: $base-line-height*1.2;
    }

    @media #{$mq-large} {
      font-size: $base-font-size*1.3;
    }

    @media #{$mq-xlarge} {
      font-size: $base-font-size*1.4;
    }

    @media #{$mq-xxlarge} {
      font-size: $base-font-size*1.6;
    }
  }


  .container {
    margin: 0 4%;

    @media #{$mq-medium} {
      margin: 0 8%;
    }

    @media #{$mq-large} {
      margin: 0 12%;
    }

    @media #{$mq-xlarge} {
      margin: 0 18%;
    }
  }



  video {
    object-fit: contain;
  }

  .kiosk-page {
    height: 100vh;
    width: 100vw;
    cursor:none;
    background:black;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    overflow:hidden;
  }

  .image {
    height:100%;
    width:100%;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  .offline-time {
    color: #FFFFFF;
    text-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #FF2D95 0px 0px 20px, #FF2D95 0px 0px 30px, #FF2D95 0px 0px 40px, #FF2D95 0px 0px 50px, #FF2D95 0px 0px 75px;    
  }

  .offline-time-1 {
    color: #A7DD3C;
    text-shadow: 2px 0 0px #800040, 3px 2px 0px rgba(77,0,38,0.5), 3px 0 3px #FF002B, 5px 0 3px #800015, 6px 2px 3px rgba(77,0,13,0.5), 6px 0 9px #FF5500, 8px 0 9px #802A00, 9px 2px 9px rgba(77,25,0,0.5), 9px 0 18px #FFD500, 11px 0 18px #806A00, 12px 2px 18px rgba(77,66,0,0.5), 12px 0 30px #D4FF00, 14px 0 30px #6A8000, 15px 2px 30px rgba(64,77,0,0.5), 15px 0 45px #80FF00, 17px 0 45px #408000, 17px 2px 45px rgba(38,77,0,0.5);
  }


  .animated {
    background-image: url(/css/images/logo.png);
    background-repeat: no-repeat;
    background-position: left top;
    padding-top:95px;
    margin-bottom:60px;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  .clock-display {
    position:absolute;
    bottom:5px;
    left:5px;
    width:15vw;
    height:7vw;
    border-radius:.5em;
    background:black;

    /* Clock Day of the week */
    div:first-child {
      width:100%;
      padding:0;
      margin:0;
      text-align:center;
      font-size:2vw;
      font-style:bold;
      color:white;
    }

    /* Clock Time */
    div:nth-child(2) {
      font-family:Arial,sans serif;font-style:bold;color:yellow;
      width:100%;
      font-variant-numeric:tabular-nums;
      padding: 0 .25em 0 .25em;
      margin:0;
      text-align:center;
      font-size:3vw;
      line-height:50%;

    }


  }


</style>