<template>
  <div class="video-page" :style="cstyle">
    <div v-if="newsItem && newsItem.moreInformation" style="position:absolute;top:0;left:0;width:100%;height:100%;z-index:0;">
      <iframe  style="height:100%;width:100%;"  id="youtube-video" allow="autoplay; loop; encrypted-media; show-info; controls; fullscreen;" allowfullscreen
          :src="newsItem.moreInformation+'?rel=0&autoplay=1&loop=1&show-info=0;&controls=0;'"   >
      </iframe>
    </div>
    <div v-else>
      <h2 class="news-title">Welcome</h2>
      <p class="news-description">No current videos</p>
    </div>
  </div>
</template>
<script>

 import GeneralService from "@/services/GeneralService.js";

export default {
  props: {
    cstyle        : {type:String, default:""},
    config        : { type: Object, default: () => { return { id:1, displaySeconds:10}}}
  },
  data() {
    return {
      newsId          : null,
      pageInterval    : null,
      updateSeconds   : 30,     // how often we update/eval this component
      secondsCounter  : 0,
      refreshCounter  : 0,
      currentNewsIndex: -1,
      lastEvent       : null,
      news            : [],
      newsItem        : null
    }
  },
   beforeDestory() {
    this._clearAllIntervals();
  },
  created() {
    console.log( "News component created");
  },
  async mounted() {
    // setup data
    this.newsId        = this.config.id || -1;
    this.updateSeconds = this.config.displaySeconds || 10;

    await this.getNews();

    // setup timers
    this._clearAllIntervals();
    this.pageInterval = setInterval( this.pageTimer,1000);
  },
  methods: {
     _clearAllIntervals() {
      clearInterval( this.pageTimer );
    },
    _updateCounters() {
      let t=this;
      t.secondsCounter++;
      t.refreshCounter++;
    },
    async pageTimer() {
      let t = this;

      let rightNow       = new Date();
      let currentSeconds = rightNow.getSeconds();

      t.lastEvent = rightNow;
      t._updateCounters();

      if( t.secondsCounter >= t.updateSeconds ) {   // Check for update from server.
        t.rotateNews();
        t.secondsCounter = 0;
      }

      if( t.refreshCounter > 600 ) {
        t.refreshCounter = 0;
        await t.getNews();
      }

    },
    rotateNews() {
      if( !this.news || this.news.length === 0 ) {    // nothing to show....
        this.newsItem = null
        return;
      }
      this.currentNewsIndex++;

      if( this.currentNewsIndex >= this.news.length ) {
        this.currentNewsIndex = 0;
      }

      this.newsItem = this.news[ this.currentNewsIndex ];
      this.updateSeconds = this.newsItem.displayTime || 10;      // 18-May-21 wjs :: update this to allow mins:seconds time format

    },
    async getNews() {

      try {
        let response = await GeneralService.getNews();
        this.news = response.data; // .filter(t => t.moreInformation > "");

      } catch( error ) {
        console.log( error.message );
      }
      // this.news.push( {title:'news 1', description: 'Well, this is some news for you'});
      // this.news.push( {title:'news 2', description: 'Well, this is some more news for you'});
      // this.news.push( {title:'news 3', description: 'Well, this is even some more news for you'});

      this.currentNewsIndex = -1;
      this.rotateNews();
    },
    videoComplete( e ) {
      console.log( "Video ended " , e );
    },
    videoAbort( e ) {
      console.log( "Video abort" , e );
    },
    videoPlay( e ) {
      this.imageTimer = Math.floor(parseFloat( e.srcElement.duration) +.8 )
    },
    videoSeeked( e ) {
    },
    videoProgress( e ) {
    },
  }

}
</script>
<style scoped>

  .slide-fade-enter-active {
    transition: all .1s ease;
  }
  .slide-fade-leave-active {
    transition: all 1s ease; /* cubic-bezier(1.0, 0.5, 0.8, 1.0); */
  }
  .slide-fade-enter
    /* .slide-fade-leave-active for <2.1.8 */ {
    /*transform: translateY(-20px);*/
    opacity: 30%;
  }

  .slide-fade-leave-to {
    transform: translateY(20px);
    opacity: 0%;
  }

  .video-page {
    /* background:red; */
    /* background:#eee; */
    /* position:relative; */
    /* top:270px; */
    /* border-radius:1em; */

    /* margin-left:auto; */
    /* margin-right:auto; */

    /* width:80%; */
    /* height:55%; */

    -webkit-box-shadow: 5px 5px 20px -3px rgba(0,0,0,0.7);
    box-shadow: 5px 5px 20px -3px rgba(0,0,0,0.7);

    text-align:center;
  }

  /* .news-countdown-timer {
    position:absolute;
    font-size:1.5em;
    bottom:5px;
    right:20px;
  } */


  .news-title {
    width:100%;
    /* color:white; */
    border-bottom:5px solid black;
  }



  h2 {
    font-size: 64pt;
  }

  p {
    font-size:36pt;
    font-weight:300;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    line-height:34pt;
    padding:.5em;
    white-space:pre-line;
  }

</style>
