<template>
  <div class="news-page" :style="pageStyle()">
    <div v-if="newsItem && (newsItem.moreInformation||'')>'' && newsItem.moreInformation.indexOf('http')>=0" style="position:absolute;top:0;left:0;width:100%;height:100%;z-index:0;">
      <iframe  style="height:100%;width:100%;"  id="guess-video" allow="autoplay; loop; encrypted-media; allowfullscreen; fullscreen;"
          :src="newsItem.moreInformation+'?rel=0;&autoplay=1;&mute=1;&loop=1;&full-screen=1;&show-info=0;&controls=0;'"   >
      </iframe>
      <div class="news-countdown-timer" style="font-size:12px;color:white;font-weight:100;" >{{updateSeconds-secondsCounter}}</div>
    </div>
    <div v-else>
      <div v-if="newsItem">
        <template v-if="newsItem.title.substring(0,1) != '-' ">
          <transition name="slide-fade" mode="out-in">
          <h2 class="news-title" :style="titleStyle()" :key="newsItem.title" >{{newsItem.title}}</h2>
          </transition>
        </template>
        <transition name="slide-fade" :style="descriptionStyle()" mode="out-in">
        <div class="news-description" :key="newsItem.description" v-html="newsItem.description" ></div>
        </transition>
        <transition name="slide-fade" mode="out-in">
          <div class="news-countdown-timer" :key="secondsCounter">{{updateSeconds-secondsCounter}}</div>
        </transition>
      </div>
      <div v-else>
        <h2 class="news-title">News</h2>
        <p class="news-description">No current news ...</p>
      </div>
    </div>
  </div>
</template>
<script>

 import GeneralService from "@/services/GeneralService.js";

export default {
  props: {
    cStyle       : { type: String, default:"" },
    config       : { type: Object, default: () => { return { id:1, displaySeconds:10}}},
    dashboardData: { type: String, default: null }
  },
  data() {
    return {
      newsId          : null,
      pageInterval    : null,
      updateSeconds   : 30,     // how often we update/eval this component
      secondsCounter  : 0,
      refreshCounter  : 0,
      currentNewsIndex: -1,
      lastEvent       : null,
      news            : [],
      newsItem        : null,
      componentStyle  : null
    }
  },
  computed: {

  },
   beforeDestory() {
    this._clearAllIntervals();
  },
  created() {
    console.log( "News component created");
  },
  async mounted() {
    // setup data
    try {
      if( this.dashboardData )     {
        this.componentStyle = JSON.parse( this.dashboardData );
        // console.log( "component style=" , this.componentStyle )
      }
    } catch( error ) {
      console.log( error.message );
    }
    this.newsId        = this.config.id || -1;
    this.updateSeconds = this.config.displaySeconds || 10;

    await this.getNews();

    // setup timers
    this._clearAllIntervals();
    this.pageInterval = setInterval( this.pageTimer,1000);
  },
  methods: {
     _clearAllIntervals() {
      clearInterval( this.pageTimer );
    },
    _updateCounters() {
      let t=this;
      t.secondsCounter++;
      t.refreshCounter++;
    },
    pageStyle() {
      if( this.componentStyle ) {
        if( this.componentStyle.page ) {
          return this.componentStyle.page;
        }
      }
      return "";
    },
    titleStyle() {
      if( this.componentStyle ) {
        if( this.componentStyle.title) {
          return this.componentStyle.title;
        }
      }
      return "";
    },
    descriptionStyle() {
      if( this.componentStyle ) {
        if( this.componentStyle.description) {
          return this.componentStyle.description;
        }
      }
      return "";
    },
    async pageTimer() {
      let t = this;

      let rightNow       = new Date();
      let currentSeconds = rightNow.getSeconds();

      t.lastEvent = rightNow;
      t._updateCounters();

      if( t.secondsCounter >= t.updateSeconds ) {   // Check for update from server.
        t.rotateNews();
        t.secondsCounter = 0;
      }

      if( t.refreshCounter > 600 ) {
        t.refreshCounter = 0;
        await t.getNews();
      }

    },
    rotateNews() {
      if( !this.news || this.news.length === 0 ) {    // nothing to show....
        this.newsItem = null
        return;
      }
      this.currentNewsIndex++;

      if( this.currentNewsIndex >= this.news.length ) {
        this.currentNewsIndex = 0;
      }

      this.newsItem = this.news[ this.currentNewsIndex ];
      this.updateSeconds = this.newsItem.displayTime || 10;      // 18-May-21 wjs :: update this to allow mins:seconds time format
      // console.log( "update" , this.newsItem );

    },
    async getNews() {

      try {
        let response = await GeneralService.getNews();
        this.news = response.data;

      } catch( error ) {
        console.log( error.message );
      }
      // this.news.push( {title:'news 1', description: 'Well, this is some news for you'});
      // this.news.push( {title:'news 2', description: 'Well, this is some more news for you'});
      // this.news.push( {title:'news 3', description: 'Well, this is even some more news for you'});

      this.currentNewsIndex = -1;
      this.rotateNews();
    },
    videoComplete( e ) {
      // console.log( "Video ended " , e );
    },
    videoAbort( e ) {
      // console.log( "Video abort" , e );
    },
    videoPlay( e ) {
      this.imageTimer = Math.floor(parseFloat( e.srcElement.duration) +.8 )
    },
    videoSeeked( e ) {
    },
    videoProgress( e ) {
    },
  }

}
</script>
<style scoped>

  /* .slide-fade-enter-active {
    transition: all .5s ease;
  } */
  /* .slide-fade-leave-active {
    transition: all .5s ease; 
  }
  .slide-fade-enter{
    opacity: 30%;
  } */

  /* .slide-fade-leave-to { */
    /* transform: translateY(20px); */
    /* opacity: 0%; */
  /* } */

  .news-page {
    /* background:red; */
    background:#eee;
    position:relative;
    top:50px;
    border-radius:1em;

    margin-left:auto;
    margin-right:auto;

    width:80%;
    height:80%;

    -webkit-box-shadow: 5px 5px 20px -3px rgba(0,0,0,0.7);
    box-shadow: 5px 5px 20px -3px rgba(0,0,0,0.7);

    text-align:center;
  }

  .news-countdown-timer {
    position:absolute;
    /* font-size:1.5em; */
    font-size:12px;
    bottom:5px;
    right:20px;
  }

  .news-description {
    font-size:48px;
    font-weight:100;
    padding:0 2em 0 2em;
    line-height:1.25em;
    /* overflow-x:wrap;  */
  }

  .news-title {
    width:100%;
    /* color:white; */
    border-bottom:5px solid black;
  }

  h1 {
    font-size: 72pt;
  }

  h2 {
    font-size: 64pt;
  }

  h3 {
    font-size: 48pt;
  }

  p {
    font-size:36pt;
    font-weight:300;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    line-height:34pt;
    padding:.5em;
    white-space:pre-line;
  }

</style>
